@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

@font-face {
	font-family: 'Khmer MN';
	src: url('../../dist/font/KhmerMN-Bold.woff2') format('woff2'),
			url('../../dist/font/KhmerMN-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Khmer MN';
	src: url('../../dist/font/KhmerMN.woff2') format('woff2'),
			url('../../dist/font/KhmerMN.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}



html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'Khmer MN';
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	position: relative;
	&::before{
		content: "";
		width: 100%;
		height: 200px;
		background: transparent linear-gradient(180deg, #37373700 0%, #000000 100%) 0% 0% no-repeat padding-box;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 2;
	}
	.bg{
		position: fixed;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: right;
		z-index: 1;
		@include tablet-down{
			display: none;
		}
	}
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

ul,p,h2,h3,h4{
	margin: 0;
}

h2,h3,h4{
	color: $gold;
	text-transform: uppercase;
	font-weight: 400;
}
h2{
	font-size: 24px;
	margin-bottom: 20px;
	@include tablet-up{
		font-size: 46px;
		margin-bottom: 40px;
	}
}

header {
	position: relative;
	z-index: 9;
	padding: 20px;
	@include tablet-down{
		background-color: $black;
	}
	.container{
		text-align: center;
		.nav{
			@include flex(row,center,center);
			list-style: none;
			padding: 0;
			gap: 20px;
			li{
				a{
					font-size: 25px;
					text-transform: uppercase;
					@include hover-focus{
						color: $gold;
					}
				}
			}
		}
		.header-logo{
			display: inline-block;
			transform: translateX(-18px);
			@include tablet-up{
				display: none;
			}
		}
		.social{
			display: none;
			a{
				img{
					height: 24px;
					width: 24px;
				}
			}
		}
	}
	@include tablet-up{
		.container{
			position: relative;
			.social{
				display: flex;
				position: absolute;
				top: calc(50% - 17.5px);
				left: 0;
			}
		}
	}
}

.social{
	@include flex(row,center,center);
	gap: 20px;
	a{
		img{
			width: 30px;
			height: 30px;
			transition: all .4s;
			@include tablet-up{
				width: 35px;
				height: 35px;
			}
		}
		@include hover-focus{
			img{
				transform: scale(1.1);
			}
		}
	}
}

.btn{
	font-size: 20px;
	text-transform: uppercase;
	display: inline-block;
	text-align: center;
	padding: 12px 30px 10px;
	border-radius: 40px;
	&.btn-gold{
		background-color: $gold;
		color: $black;
	}
	&.btn-black{
		background-color: $black;
		color: $gold;
	}
	&.btn-sharp{
		border-radius: 0;
	}
}

main {
	position: relative;
	z-index: 99;
	
	section{
		
		&.hero{
			img{
				&.hero-bg{
					height: 400px;
					object-fit: cover;
					object-position: right;
					margin-bottom: 20px;
				}
				&.hero-logo{
					display: none;
				}
			}
			
			@include tablet-up{
				.social{
					display: none;
				}
				min-height: calc(100vh - 65px);
				text-align: center;
				position: relative;
				img{
					&.hero-bg{
						display: none;
					}
					&.hero-logo{
						position: absolute;
						top: calc(50% - 200px);
						left: calc(50% - 257px);
						max-width: 514px;
						display: inline-block;
						transform: translateX(-26px);
					}
				}
			}
		}
		
		&#tour{
			padding: 60px 0;
			text-align: center;
			.container{
				h2{
					margin-bottom: 0
				}
				h3{
					margin-bottom: 40px;
					color: $white;
				}
				#events{
					@include flex(column,initial,initial);
					gap: 20px;
					margin-bottom: 40px;
					.event{
						@include flex(column,center,center);
						gap: 10px;
						font-size: 16px;
						text-transform: uppercase;
						border-bottom: solid 1px $gold;
						padding-bottom: 20px;
						.btn{
							max-width: max-content;
						}
						&:nth-child(n + 5){
							display: none;
						}
					}
					&.all{
						.event{
							&:nth-child(n + 5){
								display: flex;
							}
						}
					}
				}
			}

			@include tablet-up{
				padding-top: 0;
				.container{
					#events{
						.event{
							@include flex(row,space-between,center);
							gap: 10px;
							div{
								text-align: left;
								flex: 1;
							}
						}
					}
				}
			}
		}

		&.music{
			padding: 60px 0;
			.container{
				h2{
					text-align: center;
				}
				.slider-music{
					padding: 0 40px;
					max-width: 600px;
					margin: 0 auto 40px;
					.slide{
						text-align: center;
						max-width: 500px;
						>a{
							display: block;
							img{
								display: inlin-block;
								margin-bottom: 10px;
								width: 100%;
							}
						}
					}
					.slick-arrow{
						position: absolute;
						top: calc(50% - 10px);
						font-size: 20px;
						background-color: transparent;
						color: $gold;
						border: none;
						padding: 0;
						cursor: pointer;
						z-index: 99;
						&.slick-prev{
							left: 10px;
						}
						&.slick-next{
							right: 10px;
						}
						@include tablet-up{
							font-size: 40px;
							top: calc(50% - 20px);
						}
					}
				}
			}
		}

		&.videos{
			padding: 60px 0;
			.container{
				text-align: center;
				.slider-videos{
					position: relative;
					margin-bottom: 30px;
					>div{
						text-align: center;
					}
					iframe{
						width: 100%;
						max-width: 300px;
						height: 200px;
						border: 10px solid $white;
						display: inline-block;
					}
					.slick-arrow{
						position: absolute;
						top: calc(50% - 10px);
						font-size: 20px;
						background-color: transparent;
						color: $gold;
						border: none;
						padding: 0;
						cursor: pointer;
						z-index: 99;
						&.slick-prev{
							left: 0;
						}
						&.slick-next{
							right: 0;
						}
						@include tablet-up{
							font-size: 40px;
							top: calc(50% - 20px);
						}
					}
				}
			}
			@include tablet-up{
				.container{
					.slider-videos{
						max-width: 800px;
						margin: 0 auto 30px;
						iframe{
							max-width: 700px;
							height: 375px;
						}
					}
				}
			}
		}

		&.merch{
			padding: 60px 0;
			.container{
				text-align: center;
				.slider-merch{
					padding: 0 40px;
					margin-bottom: 30px;
					.merch{
						background-color: $white;
						display: block;
						padding: 20px;
						a{
							p{
								color: $black;
								font-size: 16px;
							}
						}
					}
					.slick-arrow{
						position: absolute;
						top: calc(50% - 10px);
						font-size: 20px;
						background-color: transparent;
						color: $gold;
						border: none;
						padding: 0;
						cursor: pointer;
						z-index: 99;
						&.slick-prev{
							left: 0;
						}
						&.slick-next{
							right: 0;
						}
						@include tablet-up{
							font-size: 40px;
							top: calc(50% - 20px);
						}
					}
				}
			}
			@include tablet-up{
				padding-bottom: 160px;
				.container{
					.slider-merch{
						.slick-slide {
							margin-left: 30px;
							.slick-list {
								margin-left: -30px;
							}
						}
					}
				}
			}
		}

		&.newsletter{
			background-color: $gold;
			padding: 60px 0 0;
			>.container{
				text-align: center;
				h2{
					color: $black;
					font-size: 36px;
					max-width: 220px;
					margin: 0 auto 20px;
				}
				>.btn{
					padding-left: 60px;
					padding-right: 60px;
				}
				img{
					&.photo-mobile{
						height: 220px;
						display: block;
						margin: -20px auto -4px;
						pointer-events: none;
					}
					&.photo-desktop{
						display: none;
					}
				}
			}
			.popup{
				background-color: $black;
				position: fixed;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 999999;
				display: none;
				.container{
					height: 100%;
					@include flex(column,center,center);
					.wrapper{
						width: 100%;
					}
				}
				.form-close{
					position: absolute;
					top: 0;
					right: 0;
					background-color: $gold;
					@include flex(row,center,center);
					width: 40px;
					height: 40px;
					font-size: 20px;
					color: $black;
				}
				.filter-wrapper{
					@include flex(row,initial,initial);
					margin-bottom: 20px;
					gap: 20px;
					a{
						color: $gold;
						font-size: 20px;
					}
				}
				.form-wrapper{
					.form-es{
						display: none;
					}
				}
			}
			
			@include tablet-up{
				padding: 60px 0;
				>.container{
					@include flex(row,center,center);
					h2{
						max-width: unset;
					}
					>.wrapper{
						padding-right: 240px;
						position: relative;
					}
					img{
						&.photo-mobile{
							display: none;
						}
						&.photo-desktop{
							display: block;
							width: 240px;
							height: 320px;
							position: absolute;
							right: 0;
							bottom: -60px;
						}
					}
				}
			}
		}

	}

}

footer {
	z-index: 9;
	position: relative;
	padding: 20px;
	.copyright{
		text-align: center;
		text-transform: uppercase;
		color: #969696;
		font-family: Arial, Helvetica, sans-serif;
		p{
			font-size: 12px;
			line-height: 1.2;
			margin-bottom: 0;
			display: inline;
			a{
				color: #969696;
				@include hover-focus{
					color: $gold;
				}
			}
		}
	}
	@include tablet-up{
		padding: 60px 0 30px;
	}
}